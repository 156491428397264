import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useSkipEffect = (effect: EffectCallback, deps: DependencyList): void => {
  const isFirstRender = useRef<boolean>(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      return effect();
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
