import React, { FC, useContext, useMemo } from 'react';
import { Table } from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import { NavLink } from 'react-router-dom';
import { localURLMaker } from '../../../shared/utils/url-maker';

import { Basket } from '../types';
import { BasketContext } from '../contexts';
import { useTranslation } from 'react-i18next';

export const BasketsTable: FC<Partial<TableProps<Basket>>> = (props) => {
  const { closeSelectionDialog } = useContext(BasketContext);
  const { t } = useTranslation();

  const columns = useMemo<ColumnType<Basket>[]>(
    () => [
      {
        key: 'id',
        dataIndex: 'id',
        title: t('common.fields.id'),
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: t('common.fields.name'),
      },
      {
        key: 'declarationsCount',
        dataIndex: ['counts', 'declarations'],
        title: t('declarations.count'),
        render: (value, row) => {
          return (
            <NavLink onClick={() => closeSelectionDialog()} to={localURLMaker('/declarations', {}, { initialBasketId: row.id })}>
              {value}
            </NavLink>
          );
        },
      },
      {
        key: 'username',
        dataIndex: ['user', 'name'],
        title: t('common.fields.assigned'),
      },
    ],
    [closeSelectionDialog, t],
  );

  return <Table size='small' bordered={true} pagination={{ pageSize: 10, hideOnSinglePage: true }} columns={columns} rowKey='id' {...props} />;
};
