import React, { useMemo } from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import antLocaleAz from 'antd/lib/locale/az_AZ';
import antLocaleUS from 'antd/lib/locale/en_US';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useTranslation } from 'react-i18next';
import 'moment/locale/az';
import './infra/i18n/i18n';

import { StyledAntdGlobal } from './shared/styled/antd';

import { MeProvider } from './modules/me/context/context';
import { ParcelProvider } from './modules/parcels/contexts';
import { BasketProvider } from './modules/baskets/contexts';
import { CountryProvider } from './modules/country';

import { MainRouter } from './router';
import { TableCacheProvider } from './shared/modules/next-table/context/table-cache';
import { SettingsProvider } from './modules/settings/context';

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });

const App = () => {
  const { i18n } = useTranslation();

  const antLocale = useMemo(() => {
    switch (i18n.language) {
      case 'az':
        return antLocaleAz;
      default:
        return antLocaleUS;
    }
  }, [i18n.language]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConfigProvider locale={antLocale}>
          <MeProvider>
            <SettingsProvider>
              <CountryProvider>
                <ParcelProvider>
                  <BasketProvider>
                    <TableCacheProvider>
                      <StyledAntdGlobal />
                      <MainRouter />
                    </TableCacheProvider>
                  </BasketProvider>
                </ParcelProvider>
              </CountryProvider>
            </SettingsProvider>
          </MeProvider>
        </ConfigProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
