import { ApiResult } from '../../../shared/utils/api-result';
import { NextTableServerSideData } from '../../../shared/modules/next-table/types';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';

import { DeclarationMappers } from '../mappers';
import { Declaration } from '../types/declaration';
import { DeclarationFormData, DeclarationFormDataApi, DeclarationFormErrors } from '../types/form';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { UnknownAutoCreatedDeclaration, UnknownDeclaration } from '../types/unknown';
import { translate } from '../../../infra/i18n/i18n';

export class UnknownDeclarationsService {
  public static async getOneById(id: string | number): Promise<ApiResult<200, UnknownDeclaration> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/conflicted_declaration/info', { conflicted_declaration_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResult(200, DeclarationMappers.Unknowns.oneFromApi(data), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async getAll(
    query: Record<string, any>,
  ): Promise<ApiResult<200, Declaration[], NextTableServerSideData> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/v2/conflicted_declaration/list', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        return new ApiResult(200, DeclarationMappers.collectionFromApi(data), { total: data.total });
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async createOrUpdate(
    values: DeclarationFormData,
    type: string = 'create',
  ): Promise<ApiResult<200> | ApiResult<422, DeclarationFormErrors> | ApiResult<400 | 500, string>> {
    const url = type === 'create' ? urlMaker('/api/warehouse/conflicted_declaration/create') : urlMaker('/api/warehouse/conflicted_declaration/edit');
    const body = new FormData();

    appendToFormData<DeclarationFormDataApi>(DeclarationMappers.formDataToApi(values), body);

    try {
      const response = await caller(url, { body, method: 'POST' });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else if (response.status === 400) {
        const { errors } = await response.json();

        return new ApiResult(422, DeclarationMappers.formErrorsFromApi(errors), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
  public static async accept(
    values: any,
  ): Promise<ApiResult<200, UnknownAutoCreatedDeclaration> | ApiResult<422, DeclarationFormErrors> | ApiResult<400 | 500, string>> {
    const body = new FormData();
    appendToFormData(DeclarationMappers.Unknowns.toPersistence(values), body);
    const url = urlMaker('/api/warehouse/conflicted_declaration/accept');
    try {
      const response = await caller(url, { body, method: 'POST' });
      if (response.ok) {
        const data = await response.json();
        return new ApiResult(200, DeclarationMappers.Unknowns.autoCreatedFromApi(data), null);
      } else if (response.status === 400) {
        const { errors } = await response.json();
        return new ApiResult(422, DeclarationMappers.formErrorsFromApi(errors), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (error) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
