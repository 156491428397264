import { DeclarationWaybill, DeclarationWaybillApi } from '../types/waybill';

export class DeclarationsWaybillMappers {
  public static oneFromApi(data: DeclarationWaybillApi): DeclarationWaybill {
    return {
      id: data.declaration_id,
      trackCode: data.track_code,
      weight: data.weight,
      currency: data.currency,
      quantity: data.quantity,
      countryData: {
        carrierCompanyAddress: data.country_data.carrier_company_address,
        carrierCompanyName: data.country_data.carrier_company_name,
        countryCode: data.country_data.country_code,
        currencyType: data.country_data.currency_type,
        id: data.country_data.id,
      },
      productPrice: {
        try: Math.round(parseFloat(data.product_price_try) * 100) / 100,
        usd: Math.round(parseFloat(data.product_price_usd) * 100) / 100,
      },
      shop: data.shop_name || '',
      deliveryPrice: Math.round(parseFloat(data.delivery_price) * 100) / 100,
      totalPrice: Math.round(data.total_price * 100) / 100,
      price: data.price,
      productType: { name: data.product_type_name },
      user: {
        id: data.user_id,
        fullName: data.user_name,
        phoneNumber: data.number,
        address: data.user_address,
        passportNumber: data.passport_number,
      },
      currencyRate: Math.round(parseFloat(data.currency_rate) * 100) / 100,
      regNumber: data.RegNumber,
      printedAt: data.print_date,
    };
  }

  public static manyFromApi(data: DeclarationWaybillApi[]): DeclarationWaybill[] {
    return data.map((item) => DeclarationsWaybillMappers.oneFromApi(item));
  }

  public static errorsFromApi(errors: Record<string, string[]>): string[] {
    return Object.values(errors).flat();
  }
}
