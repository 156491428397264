import React, { Fragment, useContext, useMemo } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { BasketSelection } from './basket-selection';
import { BasketContext } from '../contexts';
import { CountryContext } from '../../country';
import { SettingsContext } from '../../settings/context';

export const BasketProcessor = () => {
  const { selectionDialogVisible, closeParcel, openSelectionDialog, closeSelectionDialog, handleSubmitSucceed, baskets, currentBasket } = useContext(
    BasketContext,
  );

  const { getCountryId } = useContext(SettingsContext);
  const { t } = useTranslation();
  const [country] = useContext(CountryContext);

  const basketsData = useMemo(() => baskets?.data.filter((basket) => basket.countryId === getCountryId(country.selected || 'tr')) || [], [
    baskets?.data,
    country.selected,
    getCountryId
  ]);

  return (
    <Fragment>
      {!currentBasket ? (
        <Button disabled={!country.selected} loading={baskets?.loading} onClick={openSelectionDialog} size='small' type='link'>
          {t('baskets.choose')}
        </Button>
      ) : (
        <Button loading={baskets?.loading} onClick={closeParcel} size='small' type='link'>
          {t('baskets.closeWithName', { name: currentBasket.name })}
        </Button>
      )}
      <BasketSelection baskets={basketsData} visible={selectionDialogVisible} onClose={closeSelectionDialog} onSubmitSucceed={handleSubmitSucceed} />
    </Fragment>
  );
};
