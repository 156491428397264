import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { message, Modal } from 'antd';
import { QueryResult } from '../../../shared/modules/use-query/types';
import { MeContext } from '../../me/context/context';
import { useBaskets } from '../hooks/use-baskets';
import { BasketService } from '../services';
import { Basket } from '../types';
import { CountryContext } from '../../country';
import { useSkipEffect } from '../../../shared/hooks/use-skip-effect';
import { useTranslation } from 'react-i18next';

export const BasketContext = createContext<{
  selectionDialogVisible: boolean;
  setSelectionDialogVisible: (value: boolean) => void;
  closeParcel: () => void;
  openSelectionDialog: () => void;
  closeSelectionDialog: () => void;
  reFetchBaskets: () => Promise<void>;
  handleSubmitSucceed: any;
  baskets: QueryResult<Basket[]> | null;
  currentBasket: null | Basket;
}>({
  selectionDialogVisible: false,
  setSelectionDialogVisible: () => null,
  closeParcel: async () => {},
  reFetchBaskets: async () => {},
  openSelectionDialog: () => {},
  closeSelectionDialog: () => {},
  handleSubmitSucceed: () => {},
  baskets: null,
  currentBasket: null,
});

export const BasketProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const { state: userState } = useContext(MeContext);
  const [selectionDialogVisible, setSelectionDialogVisible] = useState<boolean>(false);
  const [country] = useContext(CountryContext);

  const baskets = useBaskets();

  const { reFetch: reFetchBaskets } = baskets;

  const currentBasket = useMemo<Basket | null>(() => {
    return (
      baskets.data.find((basket) => {
        return !!basket.users.find((user) => user.id === userState.user.data?.id);
      }) || null
    );
  }, [baskets.data, userState.user.data]);

  const closeParcel = useCallback(
    async (confirm: boolean = true) => {
      const action = async () => {
        const result = await BasketService.close(currentBasket?.id || 0);

        if (result.status === 200) {
          await reFetchBaskets();
        } else {
          message.error(result.data);
        }
      };

      if (confirm) {
        Modal.confirm({ title: t('common.confirm'), content: t('baskets.confirm.close'), onOk: action });
      } else {
        await action();
      }
    },
    [currentBasket, reFetchBaskets,t],
  );

  const openSelectionDialog = useCallback(async () => {
    setSelectionDialogVisible(true);
  }, []);

  const closeSelectionDialog = useCallback(() => {
    setSelectionDialogVisible(false);
  }, []);

  const handleSubmitSucceed = useCallback(async () => {
    closeSelectionDialog();
    await reFetchBaskets();
  }, [closeSelectionDialog, reFetchBaskets]);

  useSkipEffect(() => {
    (async () => {
      if (!!currentBasket) {
        await closeParcel(false);
      }
    })();
  }, [country.selected]);

  return (
    <BasketContext.Provider
      value={{
        selectionDialogVisible,
        setSelectionDialogVisible,
        closeParcel,
        openSelectionDialog,
        closeSelectionDialog,
        handleSubmitSucceed,
        baskets,
        currentBasket,
        reFetchBaskets,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};
