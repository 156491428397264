import { Branch, BranchApi } from '../types';

export class BranchesMappers {
  public static fromApi(raw: BranchApi): Branch {
    return {
      id: raw.id,
      name: raw.branch_name,
    };
  }

  public static collectionFromApi(raw): Branch[] {
    return raw.data.map((item) => BranchesMappers.fromApi(item));
  }
}
