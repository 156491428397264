import { QueryFunction } from 'react-query';

import { BranchesService } from '../services';
import { Branch } from '../types';

export type GetBranchesQueryData = Branch[];
export type GetBranchesQueryKey = ['branches'];

export const GET_BRANCHES: QueryFunction<GetBranchesQueryData, GetBranchesQueryKey> = async () => {
  const result = await BranchesService.getAll();

  if (result.status === 200) {
    return result.data;
  } else {
    throw new Error(result.data);
  }
};
