import { Auth, LoginFormData, LoginFormErrors, Me } from '../types';
import { MeMappers } from '../mappers';

import { ApiResult } from '../../../shared/utils/api-result';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import Cookies from 'js-cookie';
import { translate } from '../../../infra/i18n/i18n';

export class MeService {
  public static async getMe(): Promise<ApiResult<200, Me> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/client/user');

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();

        return new ApiResult(200, MeMappers.meFromApi(data), null);
      }

      return new ApiResult(400, translate('common.errors.operationFailed'), null);
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async logout(): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/logout');

    try {
      Cookies.remove('refreshToken');
      Cookies.remove('accessToken');
      Cookies.remove('tokenType');
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResult(200, null, null);
      }

      return new ApiResult(400, translate('common.errors.operationFailed'), null);
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async login(
    formData: LoginFormData,
  ): Promise<ApiResult<200, Auth> | ApiResult<400 | 401 | 403 | 500, string> | ApiResult<422, LoginFormErrors>> {
    const url = urlMaker('/api/warehouse/token');
    const body = new FormData();
    const mappedFormData = MeMappers.loginFormToApi(formData);

    Object.entries(mappedFormData).forEach(([key, value]) => body.append(key, value));

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, MeMappers.loginFormSuccessFromApi(await response.json()), null);
      } else if (response.status === 400 || response.status === 422) {
        const { error, errors } = await response.json();

        if (error === 'invalid_grant') {
          return new ApiResult(401, translate('common.errors.loginFailed'), null);
        } else if (errors) {
          return new ApiResult(422, MeMappers.loginFormErrorsFromApi(errors), null);
        }
      } else if (response.status === 401) {
        const { message } = await response.json();

        if (message === 'Blocked') {
          return new ApiResult(403, translate('common.errors.userBanned'), null);
        }
      }

      return new ApiResult(400, translate('common.errors.operationFailed'), null);
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
