import React, { useCallback } from 'react';
import { Avatar, Dropdown, Menu, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { StyledHeader, StyledMenuFoldIcon, StyledMenuUnfoldIcon } from '../styled';
import { useHeader } from '../hooks';
import { StyledHeaderButton, StyledHeaderLeft, StyledHeaderRight, StyledPortalArea } from '../styled/header';
import { ParcelProcessor } from '../../parcels/containers/parcel';
import { CustomsPing } from '../../customs/containers/ping';
import { BasketProcessor } from '../../baskets/containers/basket-processor';
import { CountrySelector } from '../../country';
import { useCustomStatus } from '../../customs/hooks';

export const AppHeader = () => {
  const { toggleSidebar, sidebarIsOpen, avatarText, logout } = useHeader();
  const { onMouseEnter, ...customProps } = useCustomStatus()
  const { t, i18n } = useTranslation();

  const onLanguageChange = useCallback(
    async (lang: string) => {
      await i18n.changeLanguage(lang);
    },
    [i18n],
  );

  return (
    <StyledHeader $wide={!sidebarIsOpen}>
      <StyledHeaderLeft>
        {sidebarIsOpen ? <StyledMenuFoldIcon onClick={toggleSidebar} /> : <StyledMenuUnfoldIcon onClick={toggleSidebar} />}
      </StyledHeaderLeft>
      <StyledPortalArea />
      <StyledHeaderRight onMouseEnter={onMouseEnter}>
        <Space size={8}>
          <BasketProcessor />
          <ParcelProcessor />
          <CustomsPing {...customProps} />
          <CountrySelector />
        </Space>
        <Dropdown
          overlay={
            <Menu>
              <Menu.SubMenu title={t('languages.languages')} icon={<Icons.GlobalOutlined />}>
                <Menu.Item onClick={() => onLanguageChange('az')}>{t('languages.azerbaijan')}</Menu.Item>
                <Menu.Item onClick={() => onLanguageChange('en')}>{t('languages.english')}</Menu.Item>
              </Menu.SubMenu>
              <Menu.Item onClick={logout} icon={<Icons.LogoutOutlined />}>
                {t('common.actions.exit')}
              </Menu.Item>
            </Menu>
          }
        >
          <StyledHeaderButton>
            <Avatar>{avatarText}</Avatar>
          </StyledHeaderButton>
        </Dropdown>
      </StyledHeaderRight>
    </StyledHeader>
  );
};
