import { createGlobalStyle } from 'styled-components';

export const StyledAntdGlobal = createGlobalStyle`
  .ant-form-item {
    margin-bottom: 1rem;
  }

  .ant-form-large .ant-form-item-label>label {
    height: 16px;
  }
`;
