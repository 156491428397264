import React from 'react';
import { Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export const CustomsPing = ({ title, icon, status }: { title: string, status: string, icon: JSX.Element }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={title}>
      <Tag icon={icon} color={status}>
        {t('customs.short')}
      </Tag>
    </Tooltip>
  );
};
