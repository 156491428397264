import { message } from 'antd';
import { handlebars } from 'hbs';

//@ts-ignore
import waybillTemplate from '../handlebars/waybill.hbs';
import { DeclarationsService } from '../services';
import { translate } from '../../../infra/i18n/i18n';

export const printDeclarationsWaybillUseCase = async (declarationId?: string | number, flightId?: string | number, boxId?: string | number) => {
  const key = Math.round(Math.random() * 1000000).toString();
  message.loading({ key, content: translate('declarations.waybillProgress') });

  try {
    const template = await fetch(waybillTemplate).then((r) => r.text());

    const result = await DeclarationsService.Waybill.getById(declarationId, flightId, boxId);
    if (result.status === 200) {
      handlebars.registerHelper('toFixed', (value) => value.toFixed(2));
      const renderedTemplate = handlebars.compile(template)(result.data.map(elem => ({ ...elem, oneCol: elem.currency === "USD" })));

      const printWindow = window.open();

      if (printWindow) {
        var afterPrint = function () {
          printWindow.close();
        };

        if (printWindow.matchMedia) {
          var mediaQueryList = printWindow.matchMedia('print');
          mediaQueryList.onchange = function (mql) {
            if (!mql.matches) afterPrint();
          }
        }
        printWindow.onafterprint = afterPrint;
        printWindow.document.write(renderedTemplate);
        printWindow.document.close();
      } else {
        message.error({ key, content: translate('common.errors.browserError') });
      }
    } else if (result.status === 422) {
      result.data.forEach((error) => message.error({ key, content: error }));
    } else {
      message.error({ key, content: result.data });
    }
  } catch (e) {
    message.error({ key, content: translate('common.errors.templateError') });
  }
};
