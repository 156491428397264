import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { MeContext } from '../../me/context/context';
import { useParcels } from '../hooks/use-parcels';
import { ParcelService } from '../services';
import { message, Modal } from 'antd';
import { Parcel, ParcelResult } from '../types';
import { CountryContext } from '../../country';
import { useSkipEffect } from '../../../shared/hooks/use-skip-effect';
import { useTranslation } from 'react-i18next';

export type ParcelContextValues = {
  selectionDialogVisible: boolean;
  setSelectionDialogVisible: (value: boolean) => void;
  closeParcel: () => void;
  openSelectionDialog: () => void;
  closeSelectionDialog: () => void;
  handleSubmitSucceed: () => void;
  refetch: () => void;
  parcels: ParcelResult | null;
  currentParcel: Parcel | null;
};

export const ParcelContext = createContext<ParcelContextValues>({
  selectionDialogVisible: false,
  setSelectionDialogVisible: () => null,
  closeParcel: () => null,
  openSelectionDialog: () => null,
  closeSelectionDialog: () => null,
  handleSubmitSucceed: () => null,
  refetch: () => null,
  parcels: null,
  currentParcel: null,
});

export const ParcelProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const { state: userState } = useContext(MeContext);
  const [selectionDialogVisible, setSelectionDialogVisible] = useState<boolean>(false);
  const [country] = useContext(CountryContext);

  const { reFetch, data } = useParcels();

  const currentParcel = useMemo<Parcel | null>(() => data?.available.find(({ user }) => user?.id === userState.user.data?.id) || null, [
    data,
    userState.user.data,
  ]);

  const closeParcel = useCallback(
    async (confirm: boolean = true) => {
      const action = async () => {
        const result = await ParcelService.close(currentParcel?.id || 0);

        if (result.status === 200) {
          await reFetch();
        } else {
          message.error(result.data);
        }
      };

      if (confirm) {
        Modal.confirm({ title: t('common.confirm'), content: t('parcels.closeConfirm'), onOk: action });
      } else {
        await action();
      }
    },
    [currentParcel?.id, reFetch, t],
  );

  const openSelectionDialog = useCallback(async () => {
    setSelectionDialogVisible(true);
  }, []);

  const closeSelectionDialog = useCallback(() => {
    setSelectionDialogVisible(false);
  }, []);

  const handleSubmitSucceed = useCallback(async () => {
    closeSelectionDialog();
    await reFetch();
  }, [closeSelectionDialog, reFetch]);

  useSkipEffect(() => {
    (async () => {
      if (!!currentParcel) {
        await closeParcel(false);
      }
    })();
  }, [country.selected]);

  return (
    <ParcelContext.Provider
      value={{
        selectionDialogVisible,
        setSelectionDialogVisible,
        closeParcel,
        openSelectionDialog,
        closeSelectionDialog,
        handleSubmitSucceed,
        parcels: data,
        refetch: reFetch,
        currentParcel,
      }}
    >
      {children}
    </ParcelContext.Provider>
  );
};
