import React, { FC, useContext } from 'react';
import { StyledContent, StyledLayout } from '../styled';
import { AppHeader } from './header';
import { LayoutContext } from '../context';

export const AppContent: FC = ({ children }) => {
  const { state } = useContext(LayoutContext);

  return (
    <StyledLayout $wide={!state.sidebar.isOpen}>
      <AppHeader />
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  );
};
