export const languageEn = {
  translation: {
    user: {
      fields: {
        firstname: 'Firstname',
        lastname: 'Lastname',
      },
    },
    languages: {
      languages: 'Languages',
      azerbaijan: 'Azerbaijan',
      english: 'English',
      turkey: 'Turkey',
    },
    countries: {
      choose: 'Choose country',
      country: 'Country',
      tr: 'Turkey',
      us: 'USA',
    },
    common: {
      all: 'All',
      details: 'Details',
      generalinfo: 'General informations',
      exportAsExcel: 'Excel export',
      processing: 'Processing',
      pieces: 'Pieces',
      piecesWithCount: '{{count}} piece',
      piecesWithCount_plural: '{{count}} pieces',
      kgWithCount: '{{count}} kg',
      confirm: 'Confirm',
      lineSelectedWithCount: '{{count}} item selected',
      lineSelectedWithCount_plural: '{{count}} items selected',
      weightWithKG: '{{weight}} kg',
      operationSucceed: 'The operation performed successfully',
      document: 'Document',
      tin: 'TIN',
      notExists: 'Not exists',
      notEntered: 'Not entered',
      prices: 'Prices',
      sizes: 'Sizes',
      currentMonth: 'Current month',
      approved: 'Approved',
      notconfirmed: 'Not confirmed',
      executed: 'Executed',
      notexecuted: 'Not executed',
      questionConfirm: 'Are you sure you want to perform this operation?',
      questionConfirmInfo: 'It will not be possible to return the data after the operation.',
      branch: 'Branch',
      errors: {
        getRequestFailed: 'Fetching failed.',
        networkFailed: 'Connection could not be established',
        operationFailed: 'The operation could not be performed',
        browserError: 'An error has occurred in the browser.',
        templateError: 'The template could not be retrieved.',
        loginFailed: 'User information entered incorrectly.',
        userBanned: 'Your access to the system is temporarily restricted.',
        parcelNotSelected: 'Box is not selected.',
        dataNotFound: 'No information found.',
        dnsNotFound: 'Customer didn`t declare.',
      },
      fields: {
        id: 'ID',
        no: 'No',
        name: 'Name',
        assigned: 'Assigned',
        trackCode: 'Track code',
        createdAt: 'Created at',
        object: 'Object',
        otp: 'OTP',
        closedAt: 'Closed at',
        price: 'Price',
        date: 'Date',
        authorName: 'Author Name',
        authorId: 'Author Id',
        operationDate: 'Operation Date',
        weight: 'Weight',
        quantity: 'Quantity',
        type: 'Type',
        barcode: 'Barcode',
        executor: 'Executor',
        shop: 'Shop',
        height: 'Height',
        width: 'Width',
        depth: 'Depth',
        description: 'Description',
        comment: 'Comment',
        tin: 'TIN',
        totalCount: 'Total count',
        totalWeight: 'Total weight',
        totalPrice: 'Total price',
        count: 'Count',
        plan: {
          id: 'Plan ID',
          name: 'Plan',
        },
        user: {
          id: 'C. ID',
          name: 'Customer',
        },
        status: {
          id: 'Status ID',
          name: 'Status',
        },
        startedAt: 'Start date',
        endedAt: 'End date',
        country: 'Country',
        branch: 'Choose branch',
      },
      placeholders: {
        barcode: 'Enter the barcode',
        status: 'Choose status',
        user: 'Choose customer',
        height: 'Enter height',
        width: 'Enter width',
        depth: 'Enter depth',
        plan: 'Choose plan',
        chooseShop: 'Choose shop',
        enterShop: 'Enter shop',
        quantity: 'Enter quantity...',
        tin: 'Enter TIN',
        description: 'Enter description',
        comment: 'Enter comment...',
        trackCode: 'Enter track code...',
        shop: 'Enter shop...',
      },
      actions: {
        create: 'Create',
        createWithName: 'Create new {{name}}',
        accept: 'Accept',
        selectAll: 'Select all',
        reload: 'Reload',
        reset: 'Reset',
        printSticker: 'Print sticker',
        exit: 'Exit',
        update: 'Update',
        delete: 'Delete',
        close: 'Close',
        clear: 'Clear',
        search: 'Search',
        uploadDocument: 'Document upload',
        downloadDocument: 'Document upload',
        openDocument: 'Open document',
        markAsRead: 'Mark as read',
        markAsUnread: 'Mark as unread',
        trExpComparison: 'TrExp comparison',
      },
      read: {
        update: 'Update read',
        read: 'Read',
        unread: 'Unread',
      },
    },
    declarations: {
      declarations: 'Declarations',
      declaration: 'Declaration',
      minified: 'Declaration minilist',
      new: 'New declaration',
      unknowns: 'Controversial declarations',
      controversialEdit: 'Please correct the controversial declaration.',
      controversialNew: 'Yeni mübahisəli bağlama',
      controversialAccept: 'Controversial declarations acceptance',
      customs: 'Customs declarations',
      deleted: 'Deleted declarations',
      citizen: 'Citizen declarations',
      declared: 'Declared',
      undeclared: 'Undeclared',
      count: 'Declaration count',
      enterTrackCode: 'Enter track code',
      totalWeight: 'Total weight',
      totalCount: 'Total count',
      waybill: 'Waybill',
      waybillProgress: 'Waybill is being prepared',
      proforma: 'Proforma',
      sticker: 'Sticker',
      accept: 'Are you sure want to accept declaration?',
      accepted: 'Declaration accepted',
      warehouseInfo: 'Warehouse information',
      acceptUpdateState: 'Are you sure you want to change the status of the declaration?',
      acceptAutoCreate: 'Do you want the declaration to be created automatically?',
      acceptUpdateRead: 'Are you sure want to update declaration read status?',
      acceptRemoveFromBasket: 'Are you sure want to update declaration read status?',
      composition: {
        composition: 'Composition',
        liquid: 'Liquid',
        other: 'Other',
      },
      descriptionMessage: {
        start: 'In',
        middle1: '',
        middle2: 'changed declaration status to',
        end: '',
      },
      actions: {
        updateParcel: 'Update parcel',
        removeFromBasket: 'Remove from basket',
        updateBasket: 'Update basket',
        changeBasket: 'Change basket',
        addToParcel: 'Add to parcel',
        addToBasket: 'Add to basket',
        printSticker: 'Print sticker',
        printProform: 'Print proform',
        removeFromParcel: 'Remove from parcel',
        printProformDetailed: 'Proforma detailed',
        printProformEdit: 'Proforma edit',
        statusMap: 'Status map',
      },
      fields: {
        trackCode: 'Track code',
        declared: 'Declaration',
        isInDns: 'DNS',
        parcel: 'Parcel',
        prevBasket: 'Previous Basket',
        price: 'Price',
        basket: 'Basket',
        flightName: 'Flight Name',
        globalTrackCode: 'Global track code',
        globalTrackCodeShort: 'Global T.C',
        status: 'Declaration status',
        shop: 'Shop',
        statusShort: 'Dec. status',
        quantity: 'Quantity',
        name: 'Name',
        productPrice: 'Product price',
        partnerName: 'Partner name',
        deliveryPrice: 'Delivery price',
        isSpecial: 'Is special plan',
        isLiquid: 'Is liquid',
        isCommercial: 'Commercial',
        productType: {
          name: 'Product type',
        },
      },
      placeholders: {
        trackCode: 'Enter track code',
        parcel: 'Choose parcel',
        basket: 'Choose basket',
        globalTrackCode: 'Enter global track code',
        productPrice: 'Enter product price',
        deliveryPrice: 'Enter delivery price',
        productType: 'Choose product type',
        newBasket: 'Select new basket',
        newParcel: 'Select new parcel',
        name: 'Product name',
        price: 'Price',
        quantity: 'Quantity',
      },
    },
    orders: {
      orders: 'Orders',
      order: 'Order',
    },
    dashboard: {
      dashboard: 'Dashboard',
      onn3Camera: 'ONN3 Camera',
      declarationStatusStatistics: 'Declaration status statistics',
      declarationExecutorStatistics: 'Declaration executor statistics',
    },
    flights: {
      new: 'Create flight',
      flights: 'Flights',
      withDispatch: 'With dispatch',
      withoutDispatch: 'Without dispatch',
      actions: {
        printWaybill: 'Print waybill',
        downloadExcel: 'Download excel',
      },
      manifests: {
        bulk: 'Bulk manifest',
        bulkWithBags: 'Bulk manifest (with bags)',
        bulkWithBagsExcel: 'Bulk manifest (with bags excel)',
        manifest: 'Manifest',
        manifests: 'Manifests',
        withBags: 'Manifest (with bags)',
        allBags: 'Total bags',
        fullBags: 'Full bags',
        emptyBags: 'Empty bags',
        foundDeclarations: 'Found declarations',
        notFoundDeclarations: 'Not found declarations',
      },
      fields: {
        airWaybillNumber: 'Air waybill number',
        packagingLimit: 'Packaging limit',
        completion: 'Completion',
        totalWeight: 'Total weight',
        openedAt: 'Opening date',
        endedAt: 'Closing date',
      },
      placeholders: {
        name: 'Enter flight name',
        airWaybillNumber: 'Enter air waybill number',
        packagingLimit: 'Enter packaging limit',
      },
    },
    acceptance: {
      acceptance: 'Acceptance',
      thereAreAcceptancesThatCantSave: 'There are acceptances that cannot save',
    },
    supports: {
      supports: 'Supports',
      fields: {
        category: {
          name: 'Category',
        },
        messages: 'Messages',
      },
    },
    refunds: {
      refunds: 'Refunds',
      expressions: {
        noData: 'No data',
        seeDocument: 'See document',
      },
      actions: {
        printSticker: 'Print sticker',
      },
      confirm: {
        delete: 'Are you sure you want to delete the refund?',
        update: 'Are you sure you want to update the refund?',
      },
      pages: {
        create: 'Add new refund',
        update: 'Edit refund',
      },
      placeholders: {
        refundNumber: 'Enter refund number...',
        cargoCompany: 'Choose cargo name',
        direction: 'Enter direction...',
        productPrice: 'Enter price',
        productType: {
          name: 'Choose product type',
        },
      },
      fields: {
        refundNumber: 'Refund number',
        cargoCompany: 'Cargo company',
        direction: 'Direction',
        productType: {
          name: 'Product type',
        },
        productPrice: 'Product price',
      },
    },
    dns: {
      dns: 'DNS',
      accept: 'Are you sure want to accept the declaration?',
      autoAcceptance: {
        short: 'Auto acceptance',
        autoPrint: 'Auto print',
        branchCheck: 'Branch check',
        autoAcceptance: 'DNS auto acceptance',
        parcelRequired: 'To perform this operation, you must first select the parcel',
      },
      notificationTitleWithTrackCode: 'Notification of #{{trackCode}} tracking coded declaration',
      tasks: {
        titleWithTrackCode: 'DNS tasks of #{{trackCode}} tracking coded declaration',
        beingApproved: 'Your task is being approved',
        beenApproved: 'Your task has been approved',
        fields: {
          addsToForeignWarehouse: 'Adds to warehouse',
          type: 'Task type',
          branch: 'Branch',
          status: 'Task status',
        },
      },
    },
    customs: {
      short: 'Customs',
      relatedProcesses: 'Customs related processes',
    },
    barcodes: {
      confirm: {
        deleteOne: 'Are you sure to remove selected barcode?',
        deleteMany: 'Are you sure to remove selected barcodes?',
      },
    },
    logs: {
      logs: 'Logs',
      action: 'Operation',
    },
    amazonOtp: {
      amazonOtp: 'Amazon OTP',
    },
    baskets: {
      baskets: 'Baskets',
      basket: 'Basket',
      targetBasket: 'Target Basket',
      transferSuccess: 'Basket was transferred successfully',
      notselected: 'Please select any basket...',
      change: 'Change the basket of the declaration.',
      confirm: {
        delete: 'Are you sure you want to delete the basket?',
        update: 'Are you sure you want to update the basket?',
        close: 'Are you sure you want to close the basket?',
      },
      placeholders: {
        name: 'Enter basket name',
      },
      transfer: 'Transfer',
      closeWithName: 'Close basket {{name}}',
      choose: 'Choose basket',
      operations: 'Basket operations',
      choosePlaceholder: 'Choose basket',
      basketRequirement: 'You need to select a basket to transfer declarations',
    },
    parcels: {
      parcel: 'Parcel',
      parcels: 'Parcels',
      changeStatus: 'Change status',
      closeWithName: 'Close parcel {{name}}',
      open: 'Open parcel',
      choose: 'Choose parcel',
      edit: 'Change parcel',
      currentWithName: 'Current parcel - {{name}}',
      operations: 'Parcel operations',
      closeConfirm: 'Are you sure you want to close the parcel?',
      clearConfirm: 'Are you sure you want to clear the parcel?',
      change: 'Change parcel of declaration',
    },
    compare: {
      comparison: 'Comparions',
      barcodes: 'Barcodes',
      foundedBarcodes: 'Founded Barcodes',
      missingBarcodes: 'Missing Barcodes',
      balanceOut: 'Exceeded Credit Balance',
      balance: 'Balance',
    },
  },
};
