import { ILayoutActions, ILayoutState } from './types';
import { LayoutActionTypes } from './constants';

export const layoutReducer = (state: ILayoutState, action: ILayoutActions): ILayoutState => {
  switch (action.type) {
    case LayoutActionTypes.SET_LAYOUT_SIDEBAR_IS_OPEN:
      return { ...state, sidebar: { ...state.sidebar, isOpen: action.payload } };
    default:
      return state;
  }
};
