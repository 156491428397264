export const cm2Inch = (value: number): number => {
  return Math.round((value / 2.54) * 100) / 100;
};

export const inch2Cm = (value: number): number => {
  return Math.round(value * 2.54 * 100) / 100;
};

export const kg2Lbs = (value: number): number => {
  return Math.round(value * 2.205 * 100) / 100;
};

export const lbs2Kg = (value: number): number => {
  return Math.round((value / 2.205) * 100) / 100;
};
