import { FlightBulkManifestFormData, FlightBulkManifestFormDataApi, FlightBulkManifestResult, FlightBulkManifestResultApi } from '../types/bulk-manifest';

export class FlightBulkManifestMappers {
  public static uploadToApi(formData: FlightBulkManifestFormData): FlightBulkManifestFormDataApi {
    return {
      flight_id: formData.flightId,
      document_file: formData.file,
    };
  }

  public static uploadFromApi(response: FlightBulkManifestResultApi): FlightBulkManifestResult {
    return {
      file: response.zip_url,
      bags: {
        empty: response.empty_bags,
        full: response.archived_bags,
        all: response.bags,
      },
      declarations: {
        found: response.found,
        notFound: response.not_found,
      },
    };
  }
}
