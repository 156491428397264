import { Auth, Me } from '../types';
import { Constants } from './constants';
import {
  GettingMeFailedAction,
  GettingMeStartedAction,
  GettingMeSucceedAction,
  LoggingInFailedAction,
  LoggingInStartedAction,
  LoggingInSucceedAction,
  ResetMeAction,
  ResetAuthErrorsAction,
  ResetMeErrorsAction,
} from './types';

export const gettingMeStaredAction = (): GettingMeStartedAction => ({
  type: Constants.GETTING_ME_STARTED,
});

export const gettingMeSucceedAction = (user: Me): GettingMeSucceedAction => ({
  type: Constants.GETTING_ME_SUCCEED,
  payload: user,
});

export const gettingMeFailedAction = (error: string | null = null): GettingMeFailedAction => ({
  type: Constants.GETTING_ME_FAILED,
  payload: error,
});

export const loggingInStaredAction = (): LoggingInStartedAction => ({
  type: Constants.LOGGING_IN_STARTED,
});

export const loggingInSucceedAction = (auth: Auth): LoggingInSucceedAction => ({
  type: Constants.LOGGING_IN_SUCCEED,
  payload: auth,
});

export const loggingInFailedAction = (error: string | null = null): LoggingInFailedAction => ({
  type: Constants.LOGGING_IN_FAILED,
  payload: error,
});

export const resetAuthErrorsAction = (): ResetAuthErrorsAction => ({
  type: Constants.RESET_AUTH_ERRORS,
});

export const resetMeErrorsAction = (): ResetMeErrorsAction => ({
  type: Constants.RESET_USER_ERRORS,
});

export const resetMeAction = (): ResetMeAction => ({
  type: Constants.RESET_ALL,
});
