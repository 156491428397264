import { ApiResult } from '../../../shared/utils/api-result';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';

import { DeclarationMappers } from '../mappers';
import { DeclarationWaybill } from '../types/waybill';
import { translate } from '../../../infra/i18n/i18n';

export class DeclarationsWaybillService {
  public static async getById(
    declarationId?: string | number,
    flightId?: string | number,
    boxId?: string | number,
  ): Promise<ApiResult<200, DeclarationWaybill[]> | ApiResult<422, string[]> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/declaration/manifesto', { declaration_id: declarationId, flight_id: flightId, box_id: boxId });

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        const mappedData = DeclarationMappers.Waybill.manyFromApi(data);

        return new ApiResult(200, mappedData, null);
      } else if (response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = DeclarationMappers.Waybill.errorsFromApi(errors);
        return new ApiResult(422, mappedErrors, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
