import {
  UpdateDeclarationParcelFormData,
  UpdateDeclarationParcelFormDataApi,
  UpdateDeclarationParcelFormErrors,
  UpdateDeclarationParcelFormErrorsApi,
} from '../types/parcel';

export class DeclarationParcelMappers {
  public static formDataToApi(formData: UpdateDeclarationParcelFormData): UpdateDeclarationParcelFormDataApi {
    return {
      declaration_id: formData.id,
      box_id: formData.parcelId,
    };
  }

  public static formErrorsFromApi(errors: UpdateDeclarationParcelFormErrorsApi): UpdateDeclarationParcelFormErrors {
    return {
      id: errors.declaration_id?.join(', '),
      parcelId: errors.box_id?.join(', '),
      _custom: errors._custom?.join(', '),
    };
  }
}
