import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { BasketService } from '../services';
import { TinyBasket } from '../types';
import { MeContext } from '../../me/context/context';

export const useTinyBaskets = (query?: any) => {
  const { state: userState } = useContext(MeContext);

  return useQuery<TinyBasket[]>(
    useCallback(async () => {
      const result = await BasketService.getAllTiny({ page: 1, per_page: 500, ...query });

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, [query]),
    useMemo(() => ({ initialData: [], enabled: !!userState.user.data }), [userState.user.data]),
  );
};
