import { Constants } from './constants';
import { AuthActions, MeActions, MeState } from './types';
import { initialMeContextState } from './state';

export const meContextReducer = (state: MeState, action: MeActions | AuthActions): MeState => {
  switch (action.type) {
    case Constants.GETTING_ME_STARTED:
      return {
        ...state,
        user: { ...state.user, loading: true, error: null },
      };
    case Constants.GETTING_ME_SUCCEED:
      return {
        ...state,
        user: { data: action.payload, loading: false, error: null },
      };
    case Constants.GETTING_ME_FAILED:
      return {
        ...state,
        user: { ...state.user, loading: false, error: action.payload },
      };
    case Constants.RESET_AUTH_ERRORS:
      return {
        ...state,
        auth: { ...state.auth, error: null },
      };

    case Constants.LOGGING_IN_STARTED:
      return {
        ...state,
        auth: { ...state.auth, loading: true, error: null },
      };
    case Constants.LOGGING_IN_SUCCEED:
      return {
        ...state,
        auth: { data: action.payload, loading: false, error: null },
      };
    case Constants.LOGGING_IN_FAILED:
      return {
        ...state,
        auth: { ...state.auth, loading: false, error: action.payload },
      };
    case Constants.RESET_USER_ERRORS:
      return {
        ...state,
        user: { ...state.user, error: null },
      };
    case Constants.RESET_ALL:
      return { ...initialMeContextState };

    default:
      return state;
  }
};
