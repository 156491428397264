export const filterOption = (input, option) => {
  if (typeof option.children === 'string') {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else if (Array.isArray(option.children)) {
    return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else {
    return false;
  }
};

export const filterOptionWithNameOrId = (input, option) => {
  const parsedInput = parseInt(input.toLowerCase());
  if (typeof option.children === 'string') {
    return Number.isNaN(parsedInput)
      ? option.children.toLowerCase().split(' ')[3].includes(input.toLowerCase())
      : option.children.toLowerCase().split(' ')[1].includes(parsedInput.toString());
  } else if (Array.isArray(option.children)) {
    return Number.isNaN(parsedInput)
      ? option.children[option.children.length - 1].toLowerCase().includes(input.toLowerCase())
      : option.children[1].toString().toLowerCase().startsWith(parsedInput.toString());
  } else {
    return false;
  }
};

export const filterOptionStart = (input, option) => {
  if (typeof option.children === 'string') {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) === 1;
  } else if (Array.isArray(option.children)) {
    return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) === 1;
  } else {
    return false;
  }
};
