import { LoginFormData, Me, LoginApiFormData, LoginApiFormErrors, LoginFormErrors, Auth } from '../types';

export class MeMappers {
  public static meFromApi(raw: any): Me {
    return {
      id: raw.data.id,
      firstName: raw.data.name,
      lastName: raw.data.surname,
      email: raw.data.email,
      onn3Token: raw.onn3_token,
      role: (() => {
        switch (raw.data.admin) {
          case 1:
            return 'admin';
          case 2:
            return 'warehouseman';
          case 3:
            return 'back_office';
          default:
            return 'client';
        }
      })(),
      permissions: raw.permissions.map(({ code_name }) => code_name),
    };
  }

  public static loginFormToApi(formData: LoginFormData): LoginApiFormData {
    return {
      email: formData.email,
      password: formData.password,
    };
  }

  public static loginFormErrorsFromApi(errors: LoginApiFormErrors): LoginFormErrors {
    return {
      email: errors.email,
      password: errors.password,
    };
  }

  public static loginFormSuccessFromApi(response: any): Auth {
    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      tokenType: response.token_type,
      expiresIn: response.expires_in,
    };
  }
}
