import { message } from 'antd';
import { handlebars } from 'hbs';

//@ts-ignore
import proformaInvoiceTemplate from '../handlebars/proforma-invoice.hbs';
import { DeclarationsService } from '../services';

export const printDeclarationsProformaInvoiceUseCase = async (declarationIds: (string | number)[]) => {
  const key = Math.round(Math.random() * 1000000).toString();
  message.loading({ key, content: 'Proforma invoys hazırlanır.' });

  try {
    const template = await fetch(proformaInvoiceTemplate).then((r) => r.text());

    const result = await DeclarationsService.ProformaInvoice.getOneById(declarationIds);

    if (result.status === 200) {
      const renderedTemplate = handlebars.compile(template)(result.data);

      const printWindow = window.open();

      if (printWindow) {
        var afterPrint = function () {
          printWindow.close();
        };

        if (printWindow.matchMedia) {
          var mediaQueryList = printWindow.matchMedia('print');
          mediaQueryList.onchange = function (mql) {
            if (!mql.matches) afterPrint();
          }
        }
        printWindow.onafterprint = afterPrint;
        printWindow.document.write(renderedTemplate);
        printWindow.document.close();
      } else {
        message.error({ key, content: 'Brauzerdə xəta baş verdi.' });
      }
    } else if (result.status === 422) {
      result.data.forEach((error) => message.error({ key, content: error }));
    } else {
      message.error({ key, content: result.data });
    }
  } catch (e) {
    message.error({ key, content: 'Şablon əldə edilə bilmədi.' });
  }
};
