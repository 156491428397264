import { Flight, FlightApi, FlightFormErrors, FlightFormErrorsApi, FlightFormResult, FlightFormResultApi } from '../types';
import { FlightBulkManifestMappers } from './bulk-manifest';

export class FlightMappers {
  public static BulkManifest = FlightBulkManifestMappers;

  public static oneFromApi(data: FlightApi): Flight {
    return {
      id: data.id,
      name: data.name,
      countryId: data.country_id,
      total: {
        count: data.count,
        all: data.total,
        ready: data.finished,
        productPrice: parseFloat(data.price),
        deliveryPrice: parseFloat(data.delivery_price),
        weight: parseFloat(data.weight),
      },
      status: { id: data.state_id, name: data.state_name },
      openedAt: data.start_date,
      closedAt: data.end_date,
    };
  }

  public static collectionFromApi(raw): Flight[] {
    return raw.data.map((item) => FlightMappers.oneFromApi(item));
  }

  public static flightFormResultFromApi(data: FlightFormResultApi): FlightFormResult {
    return {
      closed: data.closed ? { name: data.closed.name } : null,
      opened: { name: data.created.name },
    };
  }

  public static flightFormErrorsFromApi(errors: FlightFormErrorsApi): FlightFormErrors {
    return {
      name: errors.name?.join(', '),
      _custom: errors._custom?.join(', '),
    };
  }

  public static flightGeneralErrorsFromApi(errors: Record<string, string[]>): string {
    return Object.values(errors).flat().join('. ');
  }
}
