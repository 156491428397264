import { useCallback, useContext, useMemo } from 'react';
import { LayoutContext } from '../context';
import { setLayoutSidebarIsOpen } from '../context/actions';
import { IMeContext } from '../../me/context/types';
import { MeContext } from '../../me/context/context';
import { MeService } from '../../me/services';
import { resetMeAction } from '../../me/context/actions';
import { message } from 'antd';

export const useHeader = () => {
  const { state: layout, dispatch: layoutDispatch } = useContext(LayoutContext);

  const { state: me, dispatch: meDispatch } = useContext<IMeContext>(MeContext);

  const [firstName, lastName] = useMemo<[string, string]>(() => [me.user.data?.firstName || '', me.user.data?.lastName || ''], [me.user.data]);

  const avatarText = useMemo<string>(() => firstName[0] + lastName[0], [firstName, lastName]);

  const toggleSidebar = useCallback(() => {
    layoutDispatch(setLayoutSidebarIsOpen(!layout.sidebar.isOpen));
  }, [layoutDispatch, layout.sidebar.isOpen]);

  const logout = useCallback(async () => {
    message.loading({ key: 'header-message', content: 'Əməliyyat aparılır...' });
    await MeService.logout();
    meDispatch(resetMeAction());
    message.destroy('header-message');
  }, [meDispatch]);

  return { sidebarIsOpen: layout.sidebar.isOpen, toggleSidebar, avatarText, logout };
};
