import { useCallback, useEffect, useRef, useState } from 'react';
import { UseQueryOptions } from '../types';

export const useQuery = <T = any>(query, { initialData, enabled = true, fetchOnce, initialLoading = true, polling }: UseQueryOptions = {}) => {
  const initialDataRef = useRef(initialData);
  const fetchedOnceRef = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(enabled && initialLoading);
  const [data, setData] = useState<T>(initialDataRef.current);
  const [error, setError] = useState<any>(null);
  const unmounted = useRef(false);

  const fetch = useCallback(
    async (disableLoading?: boolean) => {
      disableLoading || setLoading(true);
      try {
        const data = await query({ setError });
        if (!unmounted.current) {
          setData(data);
        }
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError('unknown');
        }
      } finally {
        disableLoading || setLoading(false);
      }
    },
    [query],
  );

  const reset = useCallback(() => {
    setData(initialDataRef.current);
  }, []);

  useEffect(() => {
    let interval;

    (async () => {
      if (enabled && !(fetchOnce && fetchedOnceRef.current)) {
        fetchedOnceRef.current = true;
        await fetch();
      }
    })();

    if (!!polling) {
      interval = setInterval(async () => {
        if (enabled && !(fetchOnce && fetchedOnceRef.current)) {
          fetchedOnceRef.current = true;
          await fetch();
        }
      }, polling);
    }

    return () => {
      clearInterval(interval);
    };
  }, [enabled, fetch, fetchOnce, polling]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return { data, loading, error, reset, reFetch: fetch };
};
