import React, { createContext, FC, useCallback, useMemo, useState } from 'react';
import { Counter } from '../types';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { CounterService } from '../services';

const initialState = {
  declarations: 0,
  unknownDeclarations: 0,
  customsReadyDeclarations: 0,
  customsTasks: 0,
  supports: 0,
};

export const CounterContext = createContext<{ state: Counter, onMouseEnter: () => void }>({
  state: initialState,
  onMouseEnter: () => { }
});

export const CounterProvider: FC = ({ children }) => {
  const [lastRequestTime, setLastRequestTime] = useState<number>(0);
  const [hovered, setHovered] = useState<boolean>(false)

  const { data, loading, reFetch } = useQuery<Counter>(
    useCallback(async () => {
      const result = await CounterService.get();

      if (result.status === 200) {
        return result.data;
      } else {
        return initialState;
      }
    }, []),
    useMemo(() => ({ initialData: initialState, enabled: hovered }), [hovered]),
  );

  const onMouseEnter = React.useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastRequestTime >= 3000) {
      setHovered(true);
      setLastRequestTime(currentTime);
      if (hovered) {
        reFetch()
      }
    }
  }, [lastRequestTime, reFetch, hovered]);

  const handleMouseEnter = () => {
    if (!loading) {
      onMouseEnter();
    }
  };

  return <CounterContext.Provider value={{ state: data, onMouseEnter: handleMouseEnter }}>{children}</CounterContext.Provider>;
};
