import { Counter, CounterApi } from '../types';

export class CounterMappers {
  public static fromApi(counter: CounterApi): Counter {
    return {
      declarations: counter.declaration,
      unknownDeclarations: counter.conflicted_declaration,
      customsReadyDeclarations: counter.declared,
      customsTasks: counter.customs_tasks_count,
      supports: counter.ticket,
    };
  }
}
