import { translate } from '../../../infra/i18n/i18n';
import { ApiResult } from '../../../shared/utils/api-result';
import { caller } from '../../../shared/utils/caller';
import { urlMaker } from '../../../shared/utils/url-maker';
import { SettingsApi } from '../interface';

export class SettingsService {
  public static async getSettings(): Promise<ApiResult<200, SettingsApi> | ApiResult<400 | 500, string>> {
    try {
      const url = urlMaker('/api/client/settings');

      const response = await caller(url);
      if (response.ok) {
        const { data } = await response.json();
        return new ApiResult(200, data, null);
      } else {
        return new ApiResult(400, translate('common.errors.networkFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
