import React, { useContext } from 'react';
import { Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SidebarLogo, StyledSider } from '../styled';
import { useSidebar } from '../hooks';
import { MeContext } from '../../me/context/context';

export const AppSidebar = () => {
  const { isOpen, activeKey, counter, onMouseEnter } = useSidebar();
  const { state: userState } = useContext(MeContext)
  const { t } = useTranslation();

  return (
    <StyledSider trigger={null} collapsible={true} width={224} collapsedWidth={46} collapsed={!isOpen}>
      <SidebarLogo>{isOpen ? <img src='/logo.svg' alt='Find Express' /> : <img src='/logo-compact.svg' alt='Find Express' />}</SidebarLogo>
      <Menu onMouseEnter={onMouseEnter} theme='light' mode='inline' selectedKeys={[activeKey]}>
        <Menu.Item key='/statistics' icon={<Icons.PieChartOutlined />}>
          {t('dashboard.dashboard')}
          <NavLink to='/statistics' />
        </Menu.Item>
        <Menu.Item key='/declarations' icon={<Icons.FileTextOutlined />}>
          {t('declarations.declarations')} {!!counter.declarations && `(${counter.declarations})`}
          <NavLink to='/declarations' />
        </Menu.Item>
        <Menu.Item key='/declarations/minified' icon={<Icons.FileTextOutlined />}>
          {t('declarations.minified')} {!!counter.declarations && `(${counter.declarations})`}
          <NavLink to='/declarations/minified' />
        </Menu.Item>
        <Menu.Item key='/declarations/unknowns' icon={<Icons.FileSearchOutlined />}>
          {t('declarations.unknowns')} {!!counter.unknownDeclarations && `(${counter.unknownDeclarations})`}
          <NavLink to='/declarations/unknowns' />
        </Menu.Item>
        <Menu.Item key='/declarations/customs' icon={<Icons.FileDoneOutlined />}>
          {t('declarations.customs')} {!!counter.customsReadyDeclarations && `(${counter.customsReadyDeclarations})`}
          <NavLink to='/declarations/customs' />
        </Menu.Item>
        <Menu.Item key='/declarations/customs/deleted' icon={<Icons.CloseCircleOutlined />}>
          {t('declarations.deleted')}
          <NavLink to='/declarations/customs/deleted' />
        </Menu.Item>
        <Menu.Item key='/customs/dns' icon={<Icons.NotificationOutlined />}>
          {t('dns.dns')} {!!counter.customsTasks && `(${counter.customsTasks})`}
          <NavLink to='/customs/dns' />
        </Menu.Item>
        <Menu.Item key='/quick_accept' icon={<Icons.CheckCircleOutlined />}>
          {t('acceptance.acceptance')}
          <NavLink to='/quick_accept' />
        </Menu.Item>
        <Menu.Item key='/flights' icon={<Icons.RocketOutlined />}>
          {t('flights.flights')}
          <NavLink to='/flights' />
        </Menu.Item>
        <Menu.Item key='/supports' icon={<Icons.MessageOutlined />}>
          {t('supports.supports')} {!!counter.supports && `(${counter.supports})`}
          <NavLink to='/supports' />
        </Menu.Item>
        <Menu.Item key='/amazon-otp' icon={<Icons.PhoneOutlined />}>
          {t('amazonOtp.amazonOtp')}
          <NavLink to='/amazon-otp' />
        </Menu.Item>
        <Menu.Item key='/refunds' icon={<Icons.RollbackOutlined />}>
          {t('refunds.refunds')}
          <NavLink to='/refunds' />
        </Menu.Item>
        <Menu.Item key='/baskets' icon={<Icons.ShoppingCartOutlined />}>
          {t('baskets.baskets')}
          <NavLink to='/baskets' />
        </Menu.Item>
        <Menu.Item key='/logs' icon={<Icons.HistoryOutlined />}>
          {t('logs.logs')}
          <NavLink to='/logs' />
        </Menu.Item>
        {!!userState.user.data?.onn3Token &&
          <Menu.Item key='/onn3_token' icon={<Icons.CameraOutlined />}>
            <a href={`https://onn3.com/package-v1?company_id=23&admin_id=${userState.user.data.id}&hash=${userState.user.data.onn3Token}`} target='_blank' rel='noreferrer noopener'>
              {t('dashboard.onn3Camera')}
            </a>
          </Menu.Item>
        }
      </Menu>
    </StyledSider>
  );
};
