import { DeclarationSticker, DeclarationStickerApi } from '../types/sticker';

export class DeclarationsStickerMappers {
  public static oneFromApi(data: DeclarationStickerApi): DeclarationSticker {
    return {
      id: data.id,
      trackCode: data.track_code,
      globalTrackCode: data.global_track_code,
      weight: parseFloat(data.weight),
      productPrice: parseFloat(data.price),
      deliveryPrice: parseFloat(data.delivery_price),
      shop: data.shop_name,
      currency: data.currency,
      branch: { id: data.branch_id, name: data.branch_name },
      productType: { name: data.category_name },
      basket: data.basket_id && data.basket_name ? { id: data.basket_id, name: data.basket_name } : null,
      user: {
        id: data.user_id,
        fullName: data.user_name,
        phoneNumber: data.number,
        address: data.address,
      },
    };
  }
}
