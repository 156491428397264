import React, { useContext, useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '../contexts';
import { SettingsContext } from '../../settings/context';

export const CountrySelector = () => {
  const { t } = useTranslation();
  const [state, handlers] = useContext(CountryContext);
  const settings = useContext(SettingsContext);

  const countries = useMemo(() => {
    return settings.data?.countries.filter((country) => !!country.has_warehouse) || []
  }, [settings.data?.countries])

  return (
    <Select value={state.selected || undefined} onChange={handlers.onSelectedCountryChange} placeholder={t('countries.country')} allowClear>
      {countries.map(country =>
        <Select.Option key={country.id} value={country.abbr}>
          {country.country_name}
        </Select.Option>
      )}
    </Select>
  );
};
