import React, { useCallback, useMemo, createContext, FC, useState, useContext, useEffect } from 'react';
import { MeContext } from '../../me/context/context';
import { SettingsContext } from '../../settings/context';


export type CountryContextValue = [
  { selected: string | null; available: string[] },
  { onSelectedCountryChange: (value: string) => void },
];

export const CountryContext = createContext<CountryContextValue>([{ selected: null, available: [] }, { onSelectedCountryChange: () => { } }]);

export const CountryProvider: FC = ({ children }) => {
  const { can } = useContext(MeContext);
  const settings = useContext(SettingsContext);

  const available = useMemo(() => {
    const countries: string[] = [];

    if (settings.data && settings.data.countries) {
      settings.data.countries.forEach(country => {
        if (can(`warehouse_${country.abbr}`)) {
          countries.push(country.abbr);
        }
      })
    }
    return countries;
  }, [can, settings]);

  const [selected, setSelected] = useState<string | null>(() => {
    const country = localStorage.getItem('warehouse.country');
    return country || null;
  });

  useEffect(() => {
    if (selected && settings.data) {
      const countryId = settings.getCountryId(selected);
      localStorage.setItem('warehouse.country_id', countryId)
      const c = settings.getCountry(countryId);
      if (c) localStorage.setItem('warehouse.measure', c.unit)
    }
  }, [selected, settings])

  const onSelectedCountryChange = useCallback((country: string | undefined) => {
    setSelected(country || null);
    localStorage.setItem('warehouse.country', country || '');
  }, []);

  const value = useMemo<CountryContextValue>(() => [{ selected, available }, { onSelectedCountryChange }], [
    available,
    onSelectedCountryChange,
    selected,
  ]);

  return <CountryContext.Provider value={value}>{children}</CountryContext.Provider>;
};
