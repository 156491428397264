import React, { useMemo } from 'react';
import { createContext, FC, useCallback } from 'react';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { ISettings, SettingsApi } from '../interface';
import { SettingsService } from '../services';

export const SettingsContext = createContext<ISettings>({
  data: {
    countries: [],
    warehouse: [],
    workinghours: '',
  },
  getCountryCode(id) {
    return "1";
  },
  getCountryId(code) {
    return 1;
  },
  getCountry(id) {
    return null;
  }
});

export const SettingsProvider: FC = ({ children }) => {

  const { data } = useQuery<{ data: SettingsApi }>(
    useCallback(async () => {
      const response = await SettingsService.getSettings();
      return response;
    }, []),
    useMemo(() => ({ initialData: null, enabled: true }), []),
  );

  const getCountryId = useCallback((country: any) => {
    if (data) {
      const elem = data.data.countries.find(elem => elem.abbr === country);
      if (elem) return elem.id;
      return null;
    }
    return null;
  }, [data])

  const getCountry = useCallback((id: number | string) => {
    if (data && id) {
      const elem = data.data.countries.find(elem => elem.id.toString() === id.toString());
      if (elem) return elem;
      return null;
    }
    return null;
  }, [data])

  const getCountryCode = useCallback((id: string | number | null) => {
    if (data && id) {
      const elem = data.data.countries.find(elem => elem.id.toString() === id.toString());
      if (elem) return elem.abbr;
      return "";
    }
    return "";
  }, [data]);

  return <SettingsContext.Provider value={{ data: data && data.data ? data.data : null, getCountryCode, getCountryId, getCountry }}>{children}</SettingsContext.Provider>;
};