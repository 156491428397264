import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { LayoutContext } from '../context';
import { CounterContext } from '../../counter/context';

export const useSidebar = () => {
  const { state: layout } = useContext(LayoutContext);
  const { state: counter, onMouseEnter } = useContext(CounterContext);
  const location = useLocation();

  return {
    isOpen: layout.sidebar.isOpen,
    activeKey: location.pathname,
    counter,
    onMouseEnter,
  };
};
