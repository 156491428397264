import {
  UpdateDeclarationBasketFormData,
  UpdateDeclarationBasketFormDataApi,
  UpdateDeclarationBasketFormErrors,
  UpdateDeclarationBasketFormErrorsApi,
} from '../types/basket';

export class DeclarationBasketMappers {
  public static formDataToApi(formData: UpdateDeclarationBasketFormData): UpdateDeclarationBasketFormDataApi {
    return {
      declaration_id: formData.id,
      basket_id: formData.basketId,
    };
  }

  public static formErrorsFromApi(errors: UpdateDeclarationBasketFormErrorsApi): UpdateDeclarationBasketFormErrors {
    return {
      id: errors.declaration_id?.join(', '),
      basketId: errors.basket_id?.join(', '),
      _custom: errors._custom?.join(', '),
    };
  }
}
