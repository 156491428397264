import i18n from 'i18next';
import { getI18n, initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

import { languageEn } from './languages/en';
import { languageAz } from './languages/az';

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources: {
      en: languageEn,
      az: languageAz,
    },
    lng: localStorage.getItem('i18nextLng') || 'az',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((e) => {
    console.error(e);
  });

export const translate = (keyword: string): string => {
  return getI18n().t(keyword);
};
