import { Declaration, DeclarationApi } from '../types/declaration';
import { DeclarationFormData, DeclarationFormDataApi, DeclarationFormErrors, DeclarationFormErrorsApi } from '../types/form';
import { DeclarationsStickerMappers } from './sticker';
import { DeclarationsWaybillMappers } from './waybill';
import { DeclarationsProformaInvoiceMappers } from './proforma-invoice';
import { UnknownDeclarationMappers } from './unknown';
import { DeclarationParcelMappers } from './parcel';
import { DeclarationBasketMappers } from './basket';
import { cm2Inch, kg2Lbs } from '../../../shared/utils/converter';
import { MinifiedDeclaration, MinifiedDeclarationApi } from '../types/minified-declaration';
import { PlanType } from '../../plans/types';
import { IStatusExecution, IStatusExecutionPersistence } from '../types/declaration-timeline';

// Both of declaration forms and unknown declaration forms use the same form mappers
export class DeclarationMappers {
  public static Sticker = DeclarationsStickerMappers;
  public static Waybill = DeclarationsWaybillMappers;
  public static ProformaInvoice = DeclarationsProformaInvoiceMappers;
  public static Unknowns = UnknownDeclarationMappers;
  public static Parcel = DeclarationParcelMappers;
  public static Basket = DeclarationBasketMappers;

  public static oneFromApi(data: DeclarationApi): Declaration {
    const unit = localStorage.getItem('warehouse.measure') || 'KG';
    return {
      id: data.id,
      globalTrackCode: data.global_track_code,
      trackCode: data.track_code,
      flightName: data.flight_name,
      prevBasket: data.prev_basket,
      weight: data.weight ? (unit === 'LBS' ? kg2Lbs(parseFloat(data.weight)) : parseFloat(data.weight)) : null,
      height: data.height ? (unit === 'LBS' ? cm2Inch(parseFloat(data.height)) : parseFloat(data.height)) : null,
      width: data.width ? (unit === 'LBS' ? cm2Inch(parseFloat(data.width)) : parseFloat(data.width)) : null,
      depth: data.depth ? (unit === 'LBS' ? cm2Inch(parseFloat(data.depth)) : parseFloat(data.depth)) : null,
      price: data.price ? parseFloat(data.price) : null,
      deliveryPrice: data.delivery_price ? parseFloat(data.delivery_price) : null,
      declared: !!data.customs,
      parcel: data.box ? { id: data.box } : null,
      basket: data.basket_id ? { id: data.basket_id, name: data.basket_name || '' } : null,
      isInDns: !!data.bbs,
      voen: data.voen,
      thisMonthPrice: parseFloat(data.last_30_days),
      quantity: data.quantity,
      isCommercial: !!data.is_commercial,
      type: data.type === 1 ? 'liquid' : 'other',
      shop: data.shop_name,
      currency: data.currency,
      file: data.document_file,
      plan: { id: data.tariff_category_id, name: data.tariff_category_name },
      status: { id: data.state_id, name: data.state_name },
      productType: { id: data.product_type_id, name: data.product_type_name },
      user: { id: data.user_id, name: data.user_name },
      editedBy: { id: data.causer_id, name: data.causer_name },
      branch: { id: data.branch_id, name: data.branch_name },
      description: data.descr || '',
      read: !data.is_new,
      createdAt: data.created_at,
      countryId: data.country_id,
      partner: {
        id: data.partner_id || 0,
        name: data.partner_name || '',
      },
    };
  }
  public static oneTimelineFromApi(data: IStatusExecutionPersistence): IStatusExecution {
    return {
      id: data.id,
      ref: {
        id: data.state_id,
        name: data.state_name,
      },
      executor: {
        id: data.user_id,
        name: data.user_name,
      },
      isCurrent: data.current,
      createdAt: data.created_at,
    };
  }

  public static collectionFromApi(raw): Declaration[] {
    return raw.data.map((item) => DeclarationMappers.oneFromApi(item));
  }

  public static minifiedFromApi(data: MinifiedDeclarationApi): MinifiedDeclaration {
    return {
      id: data.id,
      trackCode: data.track_code,
      globalTrackCode: data.global_track_code,
      price: data.price,
      shopName: data.shop_name,
      productType: { id: data.product_type_id, name: data.product_type_name },
      user: { id: data.user_id, name: data.user_name },
      createdAt: data.created_at,
    };
  }

  public static formDataToApi(formData: DeclarationFormData): DeclarationFormDataApi {
    return {
      declaration_id: formData.id,
      conflicted_declaration_id: formData.id,
      user_id: formData.userId,
      measure_id: formData.measureId,
      state_id: formData.statusId,
      global_track_code: formData.globalTrackCode,
      product_type_id: formData.productTypeId,
      quantity: formData.quantity,
      basket_id: formData.basketId.toString(),
      is_special: Number(formData.isSpecial).toString(),
      tariff_category_id: formData.planId ? Number(formData.planId).toFixed() : '',
      type: formData.isLiquid ? '1' : '2',
      descr: formData.description,
      is_commercial: formData.isCommercial ? '1' : '0',
      voen: formData.voen,
      height: formData.height ? formData.height : '',
      width: formData.width ? formData.width : '',
      depth: formData.depth ? formData.depth : '',
      weight: formData.weight ? formData.weight : '',
      price: formData.price,
      shop_name: formData.shop,
      conflicted_id: formData.unknownId,
      urgent: Number(formData.isUrgent).toString(),
      document_file: formData.file,
      boxing: formData.addToParcel !== undefined ? Number(formData.addToParcel).toString() : undefined,
      country_id: formData.countryId,
    };
  }

  public static generalErrorsFromApi(errors: Record<string, string[]>) {
    return {
      parcelNotSelected: errors.box_not_selected?.join(', '),
      parcelNotExist: errors.open_box_error?.join(', '),
      openFlightNotExist: errors.open_flights_error?.join(', '),
    };
  }

  public static formErrorsFromApi(errors: DeclarationFormErrorsApi): DeclarationFormErrors {
    return {
      id: errors.declaration_id?.join(', ') || errors.conflicted_declaration_id?.join(', '),
      userId: errors.user_id?.join(', '),
      measureId: errors.measure_id?.join(', '),
      statusId: errors.state_id?.join(', '),
      globalTrackCode: errors.global_track_code?.join(', ') || errors.declaration_unique_error?.join(', '),
      productTypeId: errors.product_type_id?.join(', '),
      quantity: errors.quantity?.join(', '),
      isSpecial: errors.is_special?.join(', '),
      planId: errors.tariff_category_id?.join(', '),
      isLiquid: errors.type?.join(', '),
      description: errors.descr?.join(', '),
      voen: errors.voen?.join(', '),
      height: errors.height?.join(', '),
      width: errors.width?.join(', '),
      depth: errors.depth?.join(', '),
      weight: errors.weight?.join(', '),
      price: errors.price?.join(', '),
      shop: errors.shop_name?.join(', '),
      unknownId: errors.conflicted_id?.join(', '),
      isUrgent: errors.urgent?.join(', '),
      file: errors.document_file?.join(', '),
      addToParcel: errors.boxing?.join(', '),
      addToBasket: errors.add_to_basket?.join(', '),
      printSticker: undefined,
    };
  }

  public static formInitializer(declaration: Declaration, planTypes?: PlanType[]): DeclarationFormData {
    const planType = planTypes?.find((planType) => {
      return planType.partner?.id === declaration.partner.id;
    });
    if (declaration.partner.id && planType?.id) {
      return {
        id: declaration.id?.toString(),
        userId: declaration.user.id?.toString(),
        measureId: '1',
        statusId: '7',
        basketId: declaration.basket?.id.toString() || '',
        globalTrackCode: declaration.globalTrackCode || '',
        voen: declaration.voen || '',
        productTypeId: declaration.productType.id?.toString() || '',
        quantity: declaration.quantity?.toString() || '',
        isSpecial: true,
        branchName: declaration.branch.name,
        isCommercial: declaration.isCommercial,
        planId: planType?.id.toString(),
        isLiquid: declaration.type === 'liquid',
        description: declaration.description,
        height: declaration.height?.toString() || '',
        width: declaration.width?.toString() || '',
        depth: declaration.depth?.toString() || '',
        weight: declaration.weight?.toString() || '',
        price: declaration.price?.toString() || '',
        shop: declaration.shop,
        unknownId: '',
        isUrgent: false,
        file: null,
        addToParcel: false,
        addToBasket: true,
        printSticker: true,
        countryId: declaration.countryId.toString(),
        partnerName: declaration.partner.name || '',
      };
    }

    return {
      id: declaration.id?.toString(),
      userId: declaration.user.id?.toString(),
      measureId: '1',
      statusId: '7',
      basketId: declaration.basket?.id.toString() || '',
      globalTrackCode: declaration.globalTrackCode || '',
      voen: declaration.voen || '',
      branchName: declaration.branch.name,
      productTypeId: declaration.productType.id?.toString() || '',
      quantity: declaration.quantity?.toString() || '',
      isSpecial: declaration.plan?.id !== 1,
      planId: declaration.plan.id?.toString() || '',
      isLiquid: declaration.type === 'liquid',
      description: declaration.description,
      height: declaration.height?.toString() || '',
      width: declaration.width?.toString() || '',
      depth: declaration.depth?.toString() || '',
      isCommercial: declaration.isCommercial,
      weight: declaration.weight?.toString() || '',
      price: declaration.price?.toString() || '',
      shop: declaration.shop,
      unknownId: '',
      isUrgent: false,
      file: null,
      addToParcel: false,
      addToBasket: true,
      printSticker: true,
      countryId: declaration.countryId.toString(),
      partnerName: declaration.partner.name || '',
    };
  }
}
