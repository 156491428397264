export enum Constants {
  GETTING_ME_STARTED = '@me/gettingMe/started',
  GETTING_ME_SUCCEED = '@me/gettingMe/succeed',
  GETTING_ME_FAILED = '@me/gettingMe/failed',
  LOGGING_IN_STARTED = '@me/loggingIn/started',
  LOGGING_IN_SUCCEED = '@me/loggingIn/succeed',
  LOGGING_IN_FAILED = '@me/loggingIn/failed',
  RESET_USER_ERRORS = '@me/resetMe/errors',
  RESET_AUTH_ERRORS = '@me/resetAuth/errors',
  RESET_ALL = '@me/resetAll',
}
