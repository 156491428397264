import React, { FC, Key, useCallback, useContext, useMemo } from 'react';
import { Button, message, Modal, Space, Table, Tooltip } from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import * as Icons from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Parcel } from '../types';
import { ParcelService } from '../services';
import { ParcelContext } from '../contexts';
import { usePrintParcelSticker } from '../hooks';

export const ParcelsTable: FC<Partial<TableProps<Parcel>>> = (props) => {
  const { refetch } = useContext(ParcelContext);
  const { t } = useTranslation();
  const printStickerById = usePrintParcelSticker();

  const exportManifest = useCallback(
    async (parcelId: string | number) => {
      message.loading({ key: '1', content: t('common.processing'), duration: null });
      const result = await ParcelService.getManifests(parcelId);


      if (result.status === 200) {
        message.destroy();
        window.open(result.data.file, '_blank');
      } else {
        message.error({ key: '1', content: result.data });
      }
    },
    [t],
  );

  const printParcelSticker = useCallback((id: string | number) => {
    return printStickerById(id)
  }, [printStickerById])

  const onClear = useCallback(
    (id: Key) => {
      const action = async () => {
        await ParcelService.clear(id);
        refetch();
      };

      Modal.confirm({
        title: t('common.confirm'),
        content: t('parcels.clearConfirm'),
        onOk: action,
      });
    },
    [refetch, t],
  );

  const columns = useMemo<ColumnType<Parcel>[]>(
    () => [
      {
        key: 'id',
        dataIndex: 'id',
        title: t('common.fields.id'),
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: t('common.fields.name'),
        render: (value) => `#${t('parcels.parcel')} #${value}`,
      },
      {
        key: 'declarationsCount',
        dataIndex: ['counts', 'declarations'],
        title: t('declarations.count'),
      },
      {
        key: 'manifest',
        title: t('flights.manifests.manifest'),
        render: (value, row) => {
          return (
            <Button size='small' type='link' icon={<Icons.CloudDownloadOutlined />} onClick={() => exportManifest(row.id)}>
              Manifest
            </Button>
          );
        },
      },
      {
        key: 'username',
        dataIndex: ['user', 'name'],
        title: t('common.fields.assigned'),
      },
      {
        key: 'clear',
        width: 1,
        render: (_, record) => {
          return (
            <Space>
              <Tooltip title={t('common.actions.printSticker')}>
                <Button onClick={() => printParcelSticker(record.id)} size='small' icon={<Icons.ProfileOutlined />} />
              </Tooltip>
              <Tooltip title={t('common.actions.clear')}>
                <Button size='small' onClick={() => onClear(record.id)} icon={<Icons.ClearOutlined />} />
              </Tooltip>
            </Space>
          );
        },
      },
    ],
    [exportManifest, onClear, t, printParcelSticker],
  );

  return <Table size='small' bordered={true} pagination={{ pageSize: 10, hideOnSinglePage: true }} columns={columns} rowKey='id' {...props} />;
};
