import { MeState } from './types';

export const initialMeContextState: MeState = {
  auth: {
    loading: false,
    error: null,
    data: null,
  },
  user: {
    loading: false,
    error: null,
    data: null,
  },
};
