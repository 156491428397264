import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResult } from '../../../shared/utils/api-result';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';

import { BasketMappers } from '../mappers';
import { Basket, TinyBasket } from '../types';
import { NextTableServerSideData } from '../../../shared/modules/next-table/types';
import { formDataFlat } from '../../../shared/utils/form-data-flat';
import { translate } from '../../../infra/i18n/i18n';

export class BasketService {
  public static async getAll(query: any = {}): Promise<ApiResult<200, Basket[], NextTableServerSideData> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, total } = await response.json();
        const mappedData = BasketMappers.manyFromApi(data);

        return new ApiResult(200, mappedData, { total: total });
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async getAllTiny(query: any = {}): Promise<ApiResult<200, TinyBasket[]> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/options', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        const mappedData = data.map(({ id, basket_name }) => ({ id, name: basket_name }));

        return new ApiResult(200, mappedData, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async createOrUpdate({
    id,
    name,
    countryId,
  }: {
    id?: string;
    name: string;
    countryId: string;
  }): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = id ? urlMaker('/api/warehouse/baskets/update') : urlMaker('/api/warehouse/baskets/create');
    const body = new FormData();
    appendToFormData({ basket_name: name, basket_id: id, country_id: countryId }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async remove(id: (string | number)[]): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/cancel', { basket_id: id });

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async close(id: number | string): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/unselect');
    const body = new FormData();
    appendToFormData({ basket_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async choose(id: number | string): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/select');
    const body = new FormData();
    appendToFormData({ basket_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async exportAsExcel({ declared, countryId }: { declared?: boolean; countryId?: number } = {}): Promise<
    ApiResult<200, Blob> | ApiResult<400 | 500, string>
  > {
    const url = urlMaker('/api/warehouse/baskets/export', { customs: declared, country_id: countryId });

    try {
      const response = await caller(url);

      if (response.ok) {
        const blob = await response.blob();

        return new ApiResult(200, blob, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join(', '), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async transfer(trackCodes: string[]): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/transfer');
    const body = new FormData();
    appendToFormData(formDataFlat({ track_code: trackCodes }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join('. '), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
  public static async addToContainer(id: string | number): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/customs_tasks/execute_basket', { basket_id: id });

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        if (response.status === 422 || response.status === 400 || response.status === 411) {
          const { errors } = await response.json();
          if (errors.open_flights_error) {
            return new ApiResult(400, errors.open_flights_error.join(', '), null);
          }
          if (errors.open_box_error) {
            return new ApiResult(400, errors.open_box_error.join(', '), null);
          }
          if (errors.database_problem) {
            return new ApiResult(400, errors.database_problem.join(', '), null);
          }
          if (errors.customs_task_reasons) {
            return new ApiResult(400, errors.customs_task_reasons.join(', '), null);
          }
          return new ApiResult(400, Object.values(errors).flat().join(', '), null);
        }
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async transferDeclarations(from: string | number, to: string | number): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/movement');
    const formData = new FormData();
    appendToFormData({ from, to }, formData);
    try {
      const response = await caller(url, { method: 'POST', body: formData });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        if (response.status === 400 || response.status === 422) {
          const result = await response.json();
          const errorMessage = Object.values(result.errors).flat().join(', ');
          return new ApiResult(400, errorMessage, null);
        } else {
          return new ApiResult(400, translate('common.errors.operationFailed'), null);
        }
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
