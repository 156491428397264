import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResult } from '../../../shared/utils/api-result';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';

import { ParcelMappers } from '../mappers';
import { Parcel, ParcelCreateResult, ParcelResult, TinyParcel } from '../types';
import { FlightBulkManifestResult } from '../../flights/types/bulk-manifest';
import { FlightMappers } from '../../flights/mappers';
import { Key } from 'react';
import { formDataFlat } from '../../../shared/utils/form-data-flat';
import { translate } from '../../../infra/i18n/i18n';

export class ParcelService {
  public static async getAllByFlightId(id: number | string): Promise<ApiResult<200, Parcel[]> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/boxes/flight', { flight_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        const mappedData = ParcelMappers.manyFromApi(data);

        return new ApiResult(200, mappedData, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async getAll(): Promise<ApiResult<200, ParcelResult> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/boxes', { page: 1, per_page: 5000 });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, all } = await response.json();
        const mappedData = ParcelMappers.manyFromApi(data);
        const mappedAll = ParcelMappers.manyFromApi(all);

        return new ApiResult(200, { available: mappedData, all: mappedAll }, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async getAllTiny(): Promise<ApiResult<200, TinyParcel[]> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/boxes/options', { page: 1, per_page: 5000 });

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        const mappedData: TinyParcel[] = data.map((item) => ({ id: item.id, name: item.box, branchName: item.branch_name }));

        return new ApiResult(200, mappedData, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async create(values: {
    countryId?: number;
    branchId?: string | number;
  }): Promise<ApiResult<200, ParcelCreateResult> | ApiResult<400 | 500 | 422, string>> {
    const url = urlMaker('/api/warehouse/boxes/create');
    const body = new FormData();
    appendToFormData({ country_id: values.countryId, branch_id: values.branchId }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = ParcelMappers.createResultFromApi(data);
        return new ApiResult(200, mappedData, null);
      } else {
        if (response.status === 422) {
          const body = JSON.parse(await response.text());
          const message = Object.values(body.errors)
            .map((elem: any) => elem.join(', '))
            .join(', ');
          return new ApiResult(422, message, null);
        } else {
          return new ApiResult(400, translate('common.errors.operationFailed'), null);
        }
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async close(id: number | string): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/boxes/close');
    const body = new FormData();
    appendToFormData({ box_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async choose(id: number | string): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/boxes/open');
    const body = new FormData();
    appendToFormData({ box_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async getManifests(id: string | number): Promise<ApiResult<200, FlightBulkManifestResult> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/flights/manifests_box_by_box_id', { box_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new ApiResult(200, FlightMappers.BulkManifest.uploadFromApi(data), null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join(', '), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async getWeight(id: string | number): Promise<ApiResult<200, { weight: number; count: number }> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/boxes/weight', { box_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        return new ApiResult(200, data, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join(', '), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async clear(id: Key): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/boxes/clear');
    const body = new FormData();
    appendToFormData(formDataFlat({ box_id: id }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join('. '), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async removeFromParcel(declaration_id: string | number): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/declaration/from_box_to_basket');
    const body = new FormData();
    appendToFormData({ declaration_id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join('. '), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
