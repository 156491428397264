import { useQuery } from '../../../shared/modules/use-query/hooks';
import { ParcelResult } from '../types';
import { useCallback, useContext, useMemo } from 'react';
import { ParcelService } from '../services';
import { MeContext } from '../../me/context/context';

export const useParcels = () => {
  const { state: userState } = useContext(MeContext);

  return useQuery<ParcelResult | null>(
    useCallback(async () => {
      const result = await ParcelService.getAll();

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, []),
    useMemo(() => ({ initialData: null, enabled: !!userState.user.data }), [userState.user.data]),
  );
};
