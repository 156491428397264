import { IProformDetails, IProformDetailsPersistence } from '../types/edit-proform';
import { DeclarationProformaInvoice, DeclarationProformaInvoiceApi } from '../types/proforma-invoice';

export class DeclarationsProformaInvoiceMappers {
  public static oneFromApi(data: DeclarationProformaInvoiceApi): DeclarationProformaInvoice {
    return {
      user: {
        id: data.user.id,
        fullName: data.user.user_name,
        phoneNumber: data.user.number,
        country: data.user.country,
        city: data.user.city,
        address: data.user.address,
      },
      items: data.declarations.map((declaration) => ({
        trackCode: declaration.track_code,
        quantity: declaration.quantity,
        weight: parseFloat(declaration.weight),
        price: parseFloat(declaration.price),
        unitPrice: parseFloat(declaration.unit_price),
        country: declaration.country,
        productType: { name: declaration.product_type_name },
      })),
      totalPrice: data.price,
      totalQuantity: data.total_quantity,
      totalWeight: data.weight,
      createdAt: data.date,
    };
  }
  public static detailsToDomain(data: IProformDetailsPersistence): IProformDetails {
    return {
      bundle: data.bundle.map((elem) => ({
        id: elem.id,
        kdvCapacity: elem.kdv_capacity,
        kdvRatio: elem.kdv_ratio,
        name: elem.name,
        orderDate: elem.order_date,
        paymentDate: elem.payment_date,
        price: elem.price,
        quantity: elem.quantity,
        revisionDate: elem.revision_date,
        shipingDate: elem.shiping_date,
        unitPrice: elem.unit_price,
      })),
      customer: {
        id: data.customer.user_id,
        name: data.customer.username,
      },
      single: {
        createdAt: data.single.created_at,
        id: data.single.id,
        kdvCapacity: data.single.kdv_capacity,
        kdvRatio: data.single.kdv_ratio,
        orderDate: data.single.order_date,
        paymentDate: data.single.payment_date,
        price: data.single.price,
        productTypeİd: data.single.product_type_id,
        productTypeName: data.single.product_type_name,
        quantity: data.single.quantity,
        revisionDate: data.single.revision_date,
        shipingDate: data.single.shiping_date,
        unitPrice: data.single.unit_price,
        userİd: data.single.user_id,
      },
    };
  }

  public static errorsFromApi(errors: Record<string, string[]>): string[] {
    return Object.values(errors).flat();
  }
}
