import React, { Fragment, useContext, useMemo } from 'react';
import { Button, Tooltip } from 'antd';
import { ParcelSelection } from './parcel-selection';
import { ParcelContext } from '../contexts';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '../../country';
import { SettingsContext } from '../../settings/context';

export const ParcelProcessor = () => {
  const { getCountryId } = useContext(SettingsContext)
  const { selectionDialogVisible, closeParcel, openSelectionDialog, closeSelectionDialog, handleSubmitSucceed, parcels, currentParcel } = useContext(
    ParcelContext,
  );
  const { t } = useTranslation();
  const [country] = useContext(CountryContext);

  const allParcels = useMemo(() => parcels?.all.filter((parcel) => parcel.countryId === getCountryId(country.selected || 'tr')) || [], [
    country.selected,
    parcels?.all,
    getCountryId
  ]);

  const availableParcels = useMemo(() => parcels?.available.filter((parcel) => parcel.countryId === getCountryId(country.selected || 'tr')) || [], [
    country.selected,
    parcels?.available,
    getCountryId
  ]);

  return (
    <Fragment>
      {!currentParcel ? (
        <Button disabled={!country.selected} onClick={openSelectionDialog} size='small' type='link'>
          {t('parcels.open')}
        </Button>
      ) : (
        <Tooltip title={currentParcel.branch?.name}>
          <Button onClick={closeParcel} size='small' type='link'>
            {t('parcels.closeWithName', { name: currentParcel.name })}
          </Button>
        </Tooltip>
      )}
      <ParcelSelection
        allParcels={allParcels}
        availableParcels={availableParcels}
        visible={selectionDialogVisible}
        onClose={closeSelectionDialog}
        onSubmitSucceed={handleSubmitSucceed}
      />
    </Fragment>
  );
};
