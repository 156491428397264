import { Button, Col, message, Modal, Row, Select } from 'antd';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ParcelService } from '../services';
import { Parcel } from '../types';
import { selectFilterOption } from '../../../shared/modules/form/utils';
import { ParcelsTable } from '../components/table';
import { CountryContext } from '../../country';
import { useTranslation } from 'react-i18next';
import { useGetBranchesQuery } from '../../branches/hooks/use-get-branches-query';
import { SettingsContext } from '../../settings/context';

export const ParcelSelection: FC<{
  onSubmitSucceed?: () => any;
  onClose?: () => any;
  visible?: boolean;
  availableParcels: Parcel[];
  allParcels: Parcel[];
}> = ({ onSubmitSucceed, onClose, visible, availableParcels, allParcels }) => {
  const { t } = useTranslation();
  const { getCountryId } = useContext(SettingsContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedParcelId, setSelectedParcelId] = useState<string>();
  const [country] = useContext(CountryContext);
  const [branchId, setBranchId] = useState<string>();
  const branches = useGetBranchesQuery();

  const createParcel = useCallback(async () => {
    setSubmitting(true);
    const result = await ParcelService.create({ countryId: getCountryId(country.selected), branchId });

    if (result.status === 200) {
      await onSubmitSucceed?.();
    } else if (result.status === 422) {
      message.error(result.data)
    }
    setSubmitting(false);
  }, [branchId, country.selected, onSubmitSucceed, getCountryId]);

  const chooseParcel = useCallback(async () => {
    setSubmitting(true);
    const result = await ParcelService.choose(selectedParcelId || 0);

    if (result.status === 200) {
      await onSubmitSucceed?.();
    } else {
      message.error(result.data);
    }
    setSubmitting(false);
  }, [onSubmitSucceed, selectedParcelId]);

  useEffect(() => {
    setSelectedParcelId(undefined);
  }, [visible]);

  const parcelOptions = useMemo(
    () =>
      availableParcels
        .filter((parcel) => parcel.branch?.id.toString() === branchId)
        .map((parcel) => (
          <Select.Option key={parcel.id} value={parcel.id.toString()}>
            {t('parcels.parcel')} #{parcel.name} - {t('declarations.count')}: {parcel.counts.declarations}
          </Select.Option>
        )),
    [availableParcels, branchId, t],
  );

  const branchOptions = useMemo(
    () =>
      branches.data?.map((branch) => (
        <Select.Option key={branch.id} value={branch.id.toString()}>
          {branch.name}
        </Select.Option>
      )),
    [branches.data],
  );

  const footer = useMemo(
    () => [
      <Button onClick={onClose} key='close'>
        Bağla
      </Button>,
      <Button loading={submitting} disabled={!selectedParcelId} onClick={chooseParcel} type='primary' key='change'>
        Dəyişdir
      </Button>,
      <Button loading={submitting} disabled={!!selectedParcelId || !branchId} onClick={createParcel} type='primary' key='create'>
        Yenisini aç
      </Button>,
    ],
    [branchId, chooseParcel, createParcel, onClose, selectedParcelId, submitting],
  );

  return (
    <Modal width={576} visible={visible} onCancel={onClose} title={t('parcels.operations')} footer={footer}>
      <div>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Select
              allowClear
              showSearch
              filterOption={selectFilterOption}
              value={branchId}
              onChange={setBranchId}
              placeholder={t('common.fields.branch')}
              style={{ display: 'block' }}
            >
              {branchOptions}
            </Select>
          </Col>
          <Col xs={24} md={12}>
            <Select
              allowClear
              showSearch
              disabled={!branchId}
              filterOption={selectFilterOption}
              value={selectedParcelId}
              onChange={setSelectedParcelId}
              placeholder={t('parcels.choose')}
              style={{ display: 'block' }}
            >
              {parcelOptions}
            </Select>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 24, marginBottom: -24 }}>
        <ParcelsTable dataSource={allParcels} />
      </div>
    </Modal>
  );
};
