import { message } from 'antd';
import { handlebars } from 'hbs';

//@ts-ignore
import stickerTemplate from '../handlebars/sticker.hbs';
import { ParcelService } from '../services';
import moment from 'moment';
import { Constants } from '../../../shared/constants';
import { useCallback } from 'react';


export const usePrintParcelSticker = () => {
    return useCallback(
        async (id: string | number) => {
            message.loading('Sticker hazırlanır.');
            try {
                const template = await fetch(stickerTemplate).then((r) => r.text());
                const result = await ParcelService.getWeight(id);

                if (result.status === 200) {
                    const renderedTemplate = handlebars.compile(template)({
                        data: result.data,
                        checkDate: moment().format(Constants.DATE)
                    });

                    const printWindow = window.open();

                    if (printWindow) {
                        printWindow.document.write(renderedTemplate);
                        printWindow.document.close();
                    } else {
                        message.error('Brauzerdə xəta baş verdi.');
                    }
                } else {
                    message.error(result.data);
                }
            } catch (e) {
                message.error('Şablon əldə edilə bilmədi.');
            }
        }, [])
};
