import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResult } from '../../../shared/utils/api-result';

import { BranchesMappers } from '../mappers';
import { Branch } from '../types';
import { translate } from '../../../infra/i18n/i18n';

export class BranchesService {
  public static async getAll(): Promise<ApiResult<200, Branch[]> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/branches');

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        return new ApiResult(200, BranchesMappers.collectionFromApi(data), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
