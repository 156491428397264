import React, { useMemo, useState, useCallback } from 'react';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import * as Icons from '@ant-design/icons';
import { CustomsPingService } from '../services/ping';
import { CustomsPingResult } from '../types/ping';

export const useCustomStatus = () => {
    const [hovered, setHovered] = useState<boolean>(false);
    const [lastRequestTime, setLastRequestTime] = useState<number>(0);

    const { data, loading, reFetch } = useQuery<CustomsPingResult | null>(
        useCallback(async () => {
            if (process.env.NODE_ENV !== 'development') {
                const result = await CustomsPingService.get();
                return result.data;
            } else {
                return { status: 'warning' };
            }
        }, []),
        useMemo(() => ({ initialData: null, enabled: hovered }), [hovered]),
    );

    const onMouseEnter = React.useCallback(() => {
        const currentTime = Date.now();
        if (currentTime - lastRequestTime >= 3000) {
            setHovered(true);
            setLastRequestTime(currentTime);
            if (hovered) {
                reFetch()
            }
        }
    }, [lastRequestTime, reFetch, hovered]);

    const handleMouseEnter = () => {
        if (!loading) {
            onMouseEnter();
        }
    };

    const status = useMemo(() => (data?.status ? data.status : loading ? 'processing' : 'warning'), [data, loading]);

    const icon = useMemo(() => {
        switch (status) {
            case 'success':
                return <Icons.CheckCircleOutlined />;
            case 'error':
                return <Icons.CloseCircleOutlined />;
            case 'warning':
                return <Icons.ExclamationCircleOutlined />;
            default:
                return <Icons.SyncOutlined spin={true} />;
        }
    }, [status]);

    const title = useMemo(() => {
        switch (status) {
            case 'success':
                return 'İşləkdir';
            case 'error':
                return 'İşlək deyil';
            case 'warning':
                return 'Təyin olunmayıb';
            default:
                return 'Gözlənilir';
        }
    }, [status]);

    return {
        title,
        status,
        icon,
        onMouseEnter: handleMouseEnter,
    };
};
