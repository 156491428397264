import { Dispatch, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
  gettingMeFailedAction,
  gettingMeStaredAction,
  gettingMeSucceedAction,
  loggingInFailedAction,
  loggingInStaredAction,
  loggingInSucceedAction,
} from './actions';
import { MeService } from '../services';
import { AuthActions, MeActions, MeState } from './types';
import { Me } from '../types';

export const useBootstrapMeContext = (state: MeState, dispatch: Dispatch<MeActions | AuthActions>) => {
  const bootstrap = useCallback(async () => {
    dispatch(loggingInStaredAction());

    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken') || '';
    const tokenType = Cookies.get('tokenType') || '';
    const expiresIn = 0;

    if (accessToken) {
      dispatch(
        loggingInSucceedAction({
          accessToken,
          refreshToken,
          tokenType,
          expiresIn,
        }),
      );

      dispatch(gettingMeStaredAction());
      const userResult = await MeService.getMe();

      if (userResult.status === 200) {
        dispatch(gettingMeSucceedAction(userResult.data as Me));
      } else {
        dispatch(gettingMeFailedAction('Unauthorized'));
      }
    } else {
      dispatch(loggingInFailedAction('Unauthorized'));
    }
  }, [dispatch]);

  useEffect(() => {
    (async () => await bootstrap())();
  }, [bootstrap]);
};
