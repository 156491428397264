import { useQuery } from '../../../shared/modules/use-query/hooks';
import { DnsTask } from '../types/dns';
import { useCallback, useMemo } from 'react';
import { CustomsService } from '../services';

export const useDnsTask = (id: string | number) => {
  return useQuery<DnsTask | null>(
    useCallback(async () => {
      const result = await CustomsService.Dns.getOneById(id);

      if (result.status === 200) {
        return result.data;
      } else {
        return null;
      }
    }, [id]),
    useMemo(() => ({ initialData: null }), []),
  );
};
