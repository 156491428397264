import { caller } from '../../../shared/utils/caller';
import { urlMaker } from '../../../shared/utils/url-maker';
import { ApiResult } from '../../../shared/utils/api-result';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';

import { DeclarationMappers } from '../mappers';
import { UpdateDeclarationParcelFormData } from '../types/parcel';
import { translate } from '../../../infra/i18n/i18n';

export class DeclarationParcelService {
  public static async update(
    formData: UpdateDeclarationParcelFormData,
  ): Promise<ApiResult<200> | ApiResult<422, string> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/flights/box_edit');
    const body = new FormData();
    const mappedFormData = DeclarationMappers.Parcel.formDataToApi(formData);

    appendToFormData(mappedFormData, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        const mappedErrors = Object.values(errors).flat().join(', ');
        return new ApiResult(422, mappedErrors, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
